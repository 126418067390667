import * as React from "react";
import { graphql } from "gatsby";
import Help from "../components/help";

const helpCenterPage = () => <Help />;

export default helpCenterPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        ns
        language
      }
    }
  }
`;
